import { map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { IBaseSlice } from '../../types/prismic/baseTypes'
import { media } from '../../utils/style-utils'
import YouTube from '../HtmlSerializer/YouTube'
import { FullBleedGrid } from '../Layout/Grid'

export interface IEmbeddedYoutubeGridSlice extends IBaseSlice {
	items: {
		content: {
			embed_url: string
		}
	}[],
	slice_type: 'embedded_grid'
}

interface Props {
	sliceData: IEmbeddedYoutubeGridSlice
}

const EmbeddedYouTubeGrid: React.FC<Props> = ({ sliceData }) => {
	return (
		<Grid>
			<YouTubeCards>
				{map(sliceData.items, (item, index) => (
					<YouTube
						key={`youtube-grid-${index}`}
						element={{ oembed: item.content }}
					/>
				))}
			</YouTubeCards>
		</Grid>
	)
}

export default EmbeddedYouTubeGrid

const Grid = styled(FullBleedGrid)``

const YouTubeCards = styled.div`
	grid-column: 2 / -2;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 24px;

	${media.tablet`
			grid-template-columns: 1fr;
	`};
`
