import React from 'react'
import styled, { css } from 'styled-components'
import Link from 'next/link'

import { IPage } from '../../../types/prismic/pages/generalPage'
import { media } from '../../../utils/style-utils'
import { FullBleedGrid } from '../../Layout/Grid'
import PrismicLink from '../../Links/PrismicLink'
import { Arrow } from '../../icons'
import { theme } from '../../../styles/globalStyles'
import { IBaseSlice, IPrismicLink } from '../../../types/prismic/baseTypes'

export interface ILinkListDescriptionColumns extends IBaseSlice {
	primary: {
		section_color?: IPage.Section['section_color']
	}
	items: {
		description1: string
		title1: string
		link: IPrismicLink
	}[]
	slice_type: 'link_list_description_columns'
}

interface Props {
	sliceData: ILinkListDescriptionColumns
}

const LinkListDescriptionColumns = ({ sliceData }: Props) => {
	return (
		<StyledFullBleedGrid backgroundColor={sliceData.primary.section_color}>
			{sliceData.items.map((x, index) => {
				return (
					<StyledPrismicLink key={`description-${x.title1}`} link={x.link}>
						<h2>{x.title1}</h2>
						<p>{x.description1}</p>
						<ArrowWrap className='icon'>
							<Arrow stroke={theme.colors.gold400} rotate={180} />
						</ArrowWrap>
					</StyledPrismicLink>
				)
			})}
		</StyledFullBleedGrid>
	)
}

const StyledFullBleedGrid = styled(FullBleedGrid) <{ backgroundColor?: string }>`
	padding: var(--spacer-xl) 0;

	${({ theme: { colors }, backgroundColor }) => css`
		background-color: ${backgroundColor
			? colors[backgroundColor.toLowerCase()]
			: 'inherit'};
	`};

	${media.phone`
        padding: var(--spacer-base) 0;
    `}
`

const StyledPrismicLink = styled(PrismicLink)`
	position: relative;
	display: flex;
	padding-bottom: 15px;
	margin-top: var(--spacer-lg);
	border-bottom: 1px solid ${(props) => props.theme.colors.gold150};
	text-decoration: none;
	flex-direction: column;
	gap: 0;

	&:nth-child(-n + 2) {
		margin-top: 0;
	}

	&:nth-child(odd) {
		grid-column: 2 / span 5;
	}

	&:nth-child(even) {
		grid-column: 8 / span 5;
	}

	&:hover {
		text-decoration: none;
		
		h2 {
			color: ${(props) => props.theme.colors.gold400};
		}

		.icon {
			opacity: 1;
		}
	}

	h2 {
		padding-bottom: 8px;
		font-weight: 500;
		color: ${(props) => props.theme.colors.gold550};
		transition: color 0.25s ease;
	}

	p {
		margin: 0;
		font-weight: 400;
		color: ${(props) => props.theme.colors.grey600};
	}

	${media.phone`
		&:nth-child(2) {
			margin-top: var(--spacer-lg);
		}

		&:nth-child(odd),
		&:nth-child(even) {
        	grid-column: 2 / -2;
        }
    `}
`

const ArrowWrap = styled.div`
	position: absolute;
	right: 10px;
	bottom: 10px;
	opacity: 0;
	transition: opacity 0.25s ease;

	${media.phone`
		opacity: 1;
	`}
`

export default LinkListDescriptionColumns
