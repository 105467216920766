/* eslint-disable jsx-a11y/alt-text */
import { map, max } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import { IPrismicImage } from '../../../types/prismic/baseTypes'
import { media } from '../../../utils/style-utils'
import PrismicImage from '../../PrismicImage'
import PrismicImg from '../../PrismicImage/PrismicImg'

interface Props {
	items: {
		image: IPrismicImage
		'max-width'?: string
		image_link?: {
			url?: string
		}
	}[]
}

const HighlightImages: React.FC<Props> = ({ items }) => {
	const height = max(map(items, 'max-height'))

	return (
		<ImageContainer>
			{items.map((item, index) => {
				if (item.image && item.image.url != undefined && item.image.url != '') {
					const isLink = !!item?.image_link?.url
					return (
						<ImageWrapper
							as={isLink ? 'a' : 'div'}
							href={isLink ? item?.image_link?.url : undefined}
							target={isLink ? '_blank' : undefined}
							key={`image-${index}`}
							height={height}
						>
							<Image image={item.image} key={index} />
						</ImageWrapper>
					)
				}
			})}
		</ImageContainer>
	)
}

export default HighlightImages

const ImageContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	flex-wrap: wrap;
	
	${media.tablet`
		width: 90%;
		margin: 0 auto;
	`}
`

const ImageWrapper = styled.div<{ isLink?: boolean; height?: number }>`
	height: ${(props) => props.height}px;
	margin-top: var(--spacer-md);
`

const Image = styled(PrismicImg)`
	object-fit: contain;

	width: 100%;
	height: 100%;
`
