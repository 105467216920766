import React from 'react'
import styled from 'styled-components'

import { media } from '../../utils/style-utils'

export interface Props {
	title?: string
	className?: string
	children?: React.ReactNode
}

const SectionTitle: React.FC<Props> = ({ title, className, children }) => {
	if (!title && !children) return null
	return <Title className={className}>{title || children}</Title>
}

export default SectionTitle

export const FullBleedSectionTitle = styled(SectionTitle)`
	height: 56px;
	&& {
		grid-column: 2 / span 4;

		${media.phone`
			grid-column: 3 / -3;
		`}
	}
`

const Title = styled.h3`
	margin: 0;

	::before {
		content: '';
		position: absolute;
		background: ${(props) => props.theme.colors.gold200};
		height: 2px;
		width: 16px;
		transform: translate(-29px, 8px);
	}

	color: ${(props) => props.theme.colors.gold500};
	text-transform: uppercase;

	grid-column: 1 / -1;

	${media.phone`
		grid-column: 2 / -2;
	`}
`
