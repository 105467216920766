import React from 'react'

import { IPrismicImage } from '../../types/prismic/baseTypes'
import HeroBackground from '../Layout/HeroBackground'

interface IProps {
	showAnimation?: boolean
	image: IPrismicImage
	position?: 'top' | 'bottom' | 'center'
	imageStyle?: 'darkGold' | 'lightGold'
}

const HeroImage: React.FC<IProps> = ({ position, imageStyle, image }) => {
	if (!image) {
		// * If no background image is set, do not render
		return null
	}

	return (
		<HeroBackground
			imageStyle={imageStyle}
			backgroundUrl={image.url}
			position={position}
		/>
	)
}

export default HeroImage
