import React from 'react'
import styled from 'styled-components'
import Button, { ButtonBackgroundType } from '@kvika/button'

import { IPrismicLink } from '../../../types/prismic/baseTypes'
import PrismicLink from '../../Links/PrismicLink'

interface Props {
	primary: {
		button_text: string
		button_link: IPrismicLink
	}
}

const HighlightButton: React.FC<Props> = ({ primary }) => {
	return (
		<StyledPrismicLink link={primary.button_link}>
			<Button backgroundType={ButtonBackgroundType.Outlined}>
				{primary.button_text}
			</Button>
		</StyledPrismicLink>
	)
}

export default HighlightButton

const StyledPrismicLink = styled(PrismicLink)`
	justify-content: center;
	text-decoration: none;
	margin-top: var(--spacer-sm);
`
