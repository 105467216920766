import styled from 'styled-components'

import LinearGradientBackground from './LinearGradientBackground'

export default styled(LinearGradientBackground)<{
	url?: string
	position?: string
	imageStyle?: 'darkGold' | 'lightGold'
}>`
	height: 392px;

	background-size: cover;
	background-position: ${({ position }) => position || 'center'};
`
