import React from 'react'
import styled, { css } from 'styled-components'

interface Props {
	alt?: string
	backgroundUrl?: string
	imageStyle?: 'darkGold' | 'lightGold'
}

const darkGoldGradient = `linear-gradient(0deg, rgba(179, 137, 78, 0.1), rgba(179, 137, 78, 0.1))`
const lightGoldGradient = `linear-gradient(0deg, rgba(157, 143, 123, 0.5), rgba(157, 143, 123, 0.5))`

export default styled.div.attrs((props: Props) => ({
	role: props.alt ? 'presentation' : 'img',
	alt: props.alt ?? '',
}))<Props>`
	${({ backgroundUrl, imageStyle }) =>
		(imageStyle === 'darkGold' &&
			css`
				background: ${darkGoldGradient}, url(${backgroundUrl});
				background-blend-mode: color, normal;
			`) ||
		(imageStyle === 'lightGold' &&
			css`
				background: ${lightGoldGradient}, url(${backgroundUrl});
				background-blend-mode: screen, normal;
			`) ||
		css`
			background: url(${backgroundUrl});
		`}
`
