import { RichTextBlock } from 'prismic-reactjs'
import React from 'react'
import styled, { css } from 'styled-components'

import { GridStyles } from '../../Layout/Grid/Grid'
import RichText from '../../RichText/RichText'
import { StyledRichTextContainer } from '../../RichText/RichText.styles'

export interface IRichTextSlice {
	primary: {
		animate?: boolean // * Toggles animation in Hero pages
		title: string
		content: RichTextBlock[]
	}
	slice_type: 'shaded_richtext' | 'richtext'
	slice_label?: string
}

interface IProps {
	sliceData: IRichTextSlice
	shaded?: boolean
	className?: string
}

const RichTextSlice: React.FC<IProps> = ({
	sliceData,
	shaded = false,
	className,
}) => {
	const isShaded: boolean =
		shaded || sliceData.slice_label === 'shaded' ? true : false
	const isInCard: boolean = sliceData.slice_label === 'card' ? true : false
	const shouldAnimate = sliceData.primary.animate

	return (
		<RichTextContainer
			shouldAnimate={shouldAnimate}
			shaded={isShaded}
			isInCard={isInCard}
			className={className}
		>
			{/* the htmlSerializer likes to mess with other conent unless we wrap it in a div */}
			<StyledRichText
				serializerOptions={{
					enableFileIconAnchors: true,
					enableMediaLinks: false,
				}}
				content={sliceData.primary.content}
			/>
		</RichTextContainer>
	)
}

export const StyledRichText = styled(RichText)`
	grid-column: 1/-1;
	max-width: 76ch;

	p,
	ul,
	ol {
		${({ theme: { colors } }) => css`
			color: ${colors.grey800};
		`}

		font-family: "AkzidenzGroteskPro";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 25.6px */
	}

	h1 {
		${({ theme: { colors } }) => css`
			color: ${colors.grey800};
		`}

		font-family: 'AdobeCaslonPro';
		font-size: 38px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%; /* 45.6px */
		margin: 32px 0px 0px 0px;
	}

	h2 {
		${({ theme: { colors } }) => css`
			color: ${colors.grey800};
		`}

		font-family: 'AdobeCaslonPro';
		font-size: 32px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%; /* 48px */
		margin: 32px 0px 0px 0px;
	}

	h3 {
		${({ theme: { colors } }) => css`
			color: ${colors.grey800};
		`}

		font-family: 'AdobeCaslonPro';
		font-size: 25px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%; /* 37.5px */
		margin: 24px 0px 0px 0px;
	}

	h4 {
		${({ theme: { colors } }) => css`
			color: ${colors.gold550};
		`}
		font-family: "AkzidenzGroteskPro";
		font-size: 22px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px; /* 109.091% */
		margin: 24px 0px 0px 0px;
	}

	h5 {
		${({ theme: { colors } }) => css`
			color: ${colors.gold550};
		`}
		font-family: "AkzidenzGroteskPro";
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px; /* 133.333% */
		margin: 16px 0px 0px 0px;
	}

	h6 {
		${({ theme: { colors } }) => css`
			color: ${colors.grey600};
		`}
		font-family: "AkzidenzGroteskPro";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px; /* 150% */
		margin: 16px 0px 0px 0px;
	}

	ul {
		padding-left: 24px !important;
		margin: 8px 0px;
		list-style-position: outside !important;
	}

	ol {
		margin: 8px 0px;
		padding-left: 23px !important;
	}
`

export const RichTextContainer = styled(StyledRichTextContainer)`
	${GridStyles}
	padding: 40px 0;
`

export default RichTextSlice
