import { map } from 'lodash'
import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

import { IBaseSlice, IPrismicImage } from '../../../types/prismic/baseTypes'
import { IPage } from '../../../types/prismic/pages/generalPage'
import { media } from '../../../utils/style-utils'
import { FullBleedGrid } from '../../Layout/Grid'
import { FullBleedSectionTitle } from '../../Section/SectionTitle'
import { IInlineImageSliceProps } from '../../../types/prismic/prismicSliceTypes'

export interface IInlineImageSlice extends IBaseSlice {
	items: {
		image: IPrismicImage
		image_link?: {
			url?: string
		}
	}[]
	primary: {
		section_title: string
		section_color: IPage.Section['section_color']
		description?: string
		size: IInlineImageSliceProps["size"]
		
	}
	slice_type: 'inline_images'
}

interface Props {
	sliceData: IInlineImageSlice
}

const InlineImageSlice: React.FC<Props> = ({ sliceData }) => {
	return (
		<GridContainer backgroundColor={sliceData.primary.section_color}>
			<FullBleedSectionTitle title={sliceData.primary.section_title} />
			{sliceData.primary.description && (
				<Text>{sliceData.primary.description}</Text>
			)}
			<StyledInlineImagesContainer>
				{map(sliceData.items, ({ image, image_link }, index) => {
					const isLink = !!image_link?.url

					return (
						<StyledInlineImage
							as={isLink ? 'a' : 'div'}
							href={isLink ? image_link.url : undefined}
							target={isLink ? '_blank' : undefined}
							key={`inline-image-${index}`}
						>
							<Image
								src={image.url}
								quality={100}
								alt={image.alt || ''}
								objectFit='contain'
								layout='fill'
							/>
						</StyledInlineImage>
					)
				})}
			</StyledInlineImagesContainer>
		</GridContainer>
	)
}

export default InlineImageSlice

const GridContainer = styled(FullBleedGrid)`
	padding: 40px 0px;
`

const StyledInlineImagesContainer = styled.div`
	grid-column: 2 / -2;
	display: flex;
	flex-wrap: wrap;
	gap: 4%;

	${media.tablet`
		gap: 2%;
	`}
`

const StyledInlineImage = styled.a`
	height: 115px;
	position: relative;
	width: 22%;
	${media.tablet`
		width: 32%;
	`}
`

const Text = styled.span.attrs(() => ({
	className: 'body-large-serif',
}))`
	grid-column: 2 / span 5;
	margin-bottom: var(--spacer-base);
	color: ${(p) => p.theme.colors.grey800};
`
