import React from 'react'
import { RichTextBlock } from 'prismic-reactjs'

import HeroImage from '../HeroImage/HeroImage'
import { IBaseSlice, IPrismicImage } from '../../types/prismic/baseTypes'


export interface IHeroImageTextSlice extends IBaseSlice {
	primary: {
		animate: boolean
		text?: RichTextBlock[]
		hero_image: IPrismicImage
		position?: 'top' | 'bottom' | 'center'
		image_style?: 'darkGold' | 'lightGold'
	}
	slice_type: 'hero_image_with_text'
}


interface Props {
	sliceData: IHeroImageTextSlice
}

const HeroImageSlice: React.FC<Props> = ({ sliceData }) => {
	const showAnimation = sliceData?.primary.animate
	const image = sliceData?.primary.hero_image
	const position = sliceData?.primary.position

	const imageStyle = sliceData?.primary.image_style

	return (
		<HeroImage
			imageStyle={imageStyle}
			position={position}
			showAnimation={showAnimation}
			image={image}
		/>
	)
}

export default HeroImageSlice
