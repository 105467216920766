import {  RichTextBlock } from 'prismic-reactjs'
import React from 'react'
import styled, { css } from 'styled-components'

import { IPage } from '../../../types/prismic/pages/generalPage'
import { IBaseSlice, IPrismicImage, IPrismicLink } from '../../../types/prismic/baseTypes'
import { media } from '../../../utils/style-utils'
import { LineBorder } from '../../icons'
import { FullBleedGrid } from '../../Layout/Grid'
import PrismicLink from '../../Links/PrismicLink'
import PrismicImage from '../../PrismicImage'
import { FullBleedSectionTitle } from '../../Section/SectionTitle'
import RichText from '../../RichText/RichText'

export interface IShowcasing extends IBaseSlice {
	primary: {
		image: IPrismicImage
		heading: string
		description: RichTextBlock[]
		descriptionIndent?: boolean
		link: IPrismicLink
		section_color?: IPage.Section['section_color']
		section_title?: IPage.Section['section_title']
	}
	slice_type: 'showcasing'
}


interface IProps {
	sliceData: IShowcasing
}

const Showcasing = ({ sliceData }: IProps) => {

	return (
		<GridContainer backgroundColor={sliceData.primary.section_color}>
			<FullBleedSectionTitle title={sliceData.primary.section_title} />
			<ContentContainer
				descriptionIndent={sliceData.primary?.descriptionIndent}
			>
				<PrismicLink link={sliceData.primary.link}>
					<StyledHedading className='primary'>{sliceData.primary.heading}</StyledHedading>
				</PrismicLink>
				<StyledRichTextWrapper>
					<RichText content={sliceData.primary.description} serializerOptions={{enableFileIconAnchors: false, enableMediaLinks: false}} />
				</StyledRichTextWrapper>
			</ContentContainer>
			<StyledImageContainer>
				<LineBorder />
				<ImageContainer width={sliceData.primary.image.dimensions?.width}>
					<BlockLinkWrapper link={sliceData.primary.link}>
						<PrismicImageWrap>
							<PrismicImage
								layout='responsive'
								image={sliceData.primary.image}
							/>
						</PrismicImageWrap>
					</BlockLinkWrapper>
				</ImageContainer>
				<LineBorder />
			</StyledImageContainer>
		</GridContainer>
	)
}

const BlockLinkWrapper = styled(PrismicLink)`
	&& {
		display: block;
	}
`

const StyledHedading = styled.h4`
	${({ theme: { colors } }) => css`
		color: ${colors.grey800};
	`}
`

//This is a bit hacky but we need it to apply custom styles to contents of RichText from prismic
const StyledRichTextWrapper = styled.div`
	h4 {
		font-family: AdobeCaslonPro;
		font-style: normal;
		font-weight: 600;
		font-size: 2.0625rem;
		line-height: 40px;
	}

	a {
		font-family: 'AkzidenzGroteskPro';
		font-style: normal;
		font-size: 16px;
		line-height: 24px;
	}

	${media.tablet`
		padding-top: 0;
	`}
`

const GridContainer = styled(FullBleedGrid) <{ backgroundColor?: string }>`
	${({ theme: { colors }, backgroundColor }) => css`
		background-color: ${backgroundColor
			? colors[backgroundColor.toLowerCase()]
			: 'inherit'};
	`}
	padding: var(--spacer-base) 0;

	${media.tablet`
		padding: var(--spacer-md) 0;
	`}
`

const ContentContainer = styled.div<{
	descriptionIndent?: boolean
}>`
	display: flex;
	flex-direction: column;
	grid-column: ${({ descriptionIndent }) =>
		descriptionIndent ? '3 / span 5' : '2 / span 6'};

	${media.tablet`
		grid-column: 3 / -3;
	`}
`

const StyledImageContainer = styled.div`
	grid-column: 9 / span 5;
	grid-row: 1 / 3;
	${media.tablet`
		margin-top: var(--spacer-lg);
		margin-bottom: var(--spacer-lg);
		grid-column: 2 / -2;
		grid-row: unset;
	`}

	gap: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const PrismicImageWrap = styled.div`
	box-shadow: rgb(0 0 0 / 60%) 0px 20px 35px -15px;
	display: flex;
	width: 100%;
	max-height: 100%;
	justify-content: center;
	align-items: center;
`

const ImageContainer = styled.div<{ width?: number }>`
	/* ${(p) => (p.width ? `width: ${p.width}px;` : `width: 100%;`)} */
	max-width: 100%;
	/* max-height: 430px; */

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	object-fit: contain;
	text-align: center;


	img {
		max-height: 430px;
	}

	font-weight: 700;
	color: ${(p) => p.theme.colors.gold550};

	span {
		display: inline-block;
		padding-top: 40px;
	}
`

export default Showcasing
