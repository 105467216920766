import { map } from 'lodash'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import React from 'react'
import styled, { css } from 'styled-components'

import { IBaseSlice } from '../../../types/prismic/baseTypes'
import { IPage } from '../../../types/prismic/pages/generalPage'
import { media } from '../../../utils/style-utils'
import HtmlSerializer from '../../HtmlSerializer/htmlSerializer'
import { FullBleedGrid } from '../../Layout/Grid'
import { FullBleedSectionTitle } from '../../Section/SectionTitle'

export interface ICardGridSlice extends IBaseSlice {
	slice_type: 'card_grid'
	items: {
		text: RichTextBlock[]
	}[]
	primary: {
		section_color?: IPage.Section['section_color']
		section_title?: IPage.Section['section_title']
	}
}

interface IProps {
	sliceData: ICardGridSlice
	className?: string
}

const CardGridSlice: React.FC<IProps> = ({ sliceData, className }) => {
	return (
		<StyledFullBleedGrid className={className} backgroundColor={sliceData.primary.section_color}>
			<FullBleedSectionTitle title={sliceData.primary.section_title} />
			<Cards>
				{map(sliceData.items, (item, index) => {
					return (
						<CardWrapper key={`grid-card-${index}`} column={index % 2}>
							<RichText
								htmlSerializer={(...args) =>
									HtmlSerializer(...args, {
										enableFileIconAnchors: false,
										enableMediaLinks: false,
									})
								}
								render={item.text}
							/>
						</CardWrapper>
					)
				})}
			</Cards>
		</StyledFullBleedGrid>
	)
}

export default CardGridSlice

const StyledFullBleedGrid = styled(FullBleedGrid)<{ backgroundColor?: string }>`
	padding-top: var(--spacer-base);
	padding-bottom: var(--spacer-xl);

	${({ theme: { colors }, backgroundColor }) => css`
		background-color: ${backgroundColor
			? colors[backgroundColor.toLowerCase()]
			: 'inherit'};
	`};
`

const Cards = styled(FullBleedGrid)`
	padding-top: var(--spacer-md);
	row-gap: var(--spacer-base);

	${media.phone`
		padding-top: 0;
		row-gap: var(spacer--lg);
	`}
`

export const CardWrapper = styled.div<{ column: number }>`
	display: flex;
	flex-direction: column;
	grid-column: ${(p) => p.column * 5 + 3} / span 4;

	> p {
		margin-block: 4px;

		&:first-of-type {
			margin-top: 8px;
		}
	}

	a {
		text-decoration: underline;
	}

	.block-img img {
		max-width: 100%;
	}

	${media.phone`
		grid-column: 3 / -3
	`}
`
