import { ErrorMessage, Field } from 'formik'
import styled from 'styled-components'
import Input from '@kvika/input'
import { HTMLAttributes, HTMLInputTypeAttribute } from 'react'

import { media } from '../../../utils/style-utils'

interface IFormikInputTextProps {
	label: string
	formikName: string
	placeholder: string
	fullWidth: boolean
	autoComplete?: string
	type?: HTMLInputTypeAttribute
	inputMode?: HTMLAttributes<HTMLInputElement>['inputMode']
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const FormikInputText = (props: IFormikInputTextProps) => {
	const {
		label,
		formikName: name,
		fullWidth,
		placeholder,
		autoComplete,
		type = 'text',
		onChange,
		inputMode,
	} = props
	return (
		<Field name={name}>
			{({ field, meta }) => (
				<InputWrapper fullWidth={fullWidth} key={`input-${label}`}>
					<Input
						id={label}
						placeholder={placeholder}
						autoComplete={autoComplete}
						width='auto'
						label={label}
						type={type}
						inputMode={inputMode}
						{...field}
						onChange={onChange ?? field.onChange}
					/>
					{meta.error && meta.touched && (
						<StyledValidationError>
							<ErrorMessage name={name} />
						</StyledValidationError>
					)}
				</InputWrapper>
			)}
		</Field>
	)
}

export default FormikInputText

export const InputWrapper = styled.div<{ fullWidth: boolean }>`
	position: relative;
	display: flex;
	width: ${(props) => (props.fullWidth ? '100%' : 'calc(50% - 12px)')};
	margin-bottom: var(--spacer-md);
	flex-direction: column;

	> label:first-child {
		margin-bottom: 5px;
		font-weight: 500;
		color: ${(props) => props.theme.colors.grey600};
	}

	& label {
		font-weight: 500;
	}

	${media.phone`
		width: 100%;
	`};
`

export const StyledValidationError = styled.div`
	position: absolute;
	top: var(--spacer-base);
	font-size: 0.875rem;
	line-height: 1.25rem;
	color: ${(props) => props.theme.colors.error};
`
