import Link from 'next/link'
import * as React from 'react'
import styled, { css } from 'styled-components'

import useLocalization from '../../hooks/utils/use-localization'
import { usePrismicRoutingContext } from '../../utils/prismic/Context'
import { resolvePrismicLink } from '../../utils/prismic/RouteHelpers'
import { media } from '../../utils/style-utils'
import { FullBleedGrid } from '../Layout/Grid'
import { FullBleedSectionTitle } from '../Section/SectionTitle'
import ShortRuler from '../Ruler/ShortRuler'
import { IBaseSlice, IPrismicLink } from '../../types/prismic/baseTypes'
import { IPage } from '../../types/prismic/pages/generalPage'

export interface ILinkCardListSlice extends IBaseSlice {
	primary: {
		section_color?: IPage.Section['section_color']
		section_title?: IPage.Section['section_title']
	}
	items: {
		link: IPrismicLink
		header: string
		description1: string
	}[]
	slice_type: 'link_card1'
}

export interface ILinkCardListProps {
	sliceData: ILinkCardListSlice
}

export function LinkCardList({ sliceData }: ILinkCardListProps) {
	const { pages } = usePrismicRoutingContext()
	const { translate } = useLocalization()

	return (
        <StyledFullBleedGrid backgroundColor={sliceData.primary.section_color}>
			<FullBleedSectionTitle title={sliceData.primary.section_title} />
			<StyledLinksWrap>
				{sliceData.items.map((item, index) => {
					const link = resolvePrismicLink(item.link, pages)
					return (
                        <StyledLinkContainer column={index % 2} key={item.header}>
							<h2>{item.header}</h2>
							<ShortRuler width='32' />
							<p>{item.description1}</p>
							<Link href={link ?? ''} passHref={true} legacyBehavior>
								{translate('slices.more')}
							</Link>
						</StyledLinkContainer>
                    );
				})}
			</StyledLinksWrap>
		</StyledFullBleedGrid>
    );
}

const StyledFullBleedGrid = styled(FullBleedGrid) <{ backgroundColor?: string }>`
	${({ theme: { colors }, backgroundColor }) => css`
		background-color: ${backgroundColor
			? colors[backgroundColor.toLowerCase()]
			: 'inherit'};
	`}
	padding: var(--spacer-base) 0 160px;

	${media.phone`
		padding: var(--spacer-base) 0;
	`}
`

const StyledLinksWrap = styled(FullBleedGrid)`
	padding-top: var(--spacer-md);
	row-gap: 90px;

	${media.phone`
		padding-top: 0;
		row-gap: 70px;
	`}
`

const StyledLinkContainer = styled.div<{ column: number }>`
	display: flex;
	flex-direction: column;
	grid-column: ${(p) => p.column * 5 + 3} / span 4;

	${media.phone`
		grid-column: 3 / -3
	`}

	h2 {
		margin-bottom: 5px;
	}

	p {
		margin-top: var(--spacer-xs);
		margin-bottom: var(--spacer-sm);
	}

	a {
		font-weight: 700;
		text-decoration: none;
		letter-spacing: 0.05rem;
		color: ${(props) => props.theme.colors.gold550};
	}
`
