import React from 'react'

import Spacer, { ISpacerSlice } from '../Spacer/Spacer'

interface IProps {
	sliceData: ISpacerSlice
}

const SpacerSlice: React.FC<IProps> = ({ sliceData }) => {
	return (
		<Spacer
			space={sliceData.primary.space}
			section_color={sliceData.primary.section_color}
		/>
	)
}

export default SpacerSlice
