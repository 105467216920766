import React from 'react'
import styled from 'styled-components'
import {  RichTextBlock } from 'prismic-reactjs'

import { media } from '../../../utils/style-utils'
import { FullBleedGrid } from '../../Layout/Grid'
import { IBaseSlice } from '../../../types/prismic/baseTypes'
import RichText from '../../RichText/RichText'


export interface ISingleRowTitleDescription extends IBaseSlice {
	primary: {
		title1: string
		description1: RichTextBlock[]
	}
	slice_type: 'single_row_title_and_description'
}

interface IProps {
	sliceData: ISingleRowTitleDescription
}

const SingleRowTitleDescription = ({ sliceData }: IProps) => {

	return (
		<FullBleedGrid>
			<ContentContainer>
				<SectionTitle>{sliceData.primary.title1}</SectionTitle>
				<StyledTextContainer>
					<RichText
						content={sliceData.primary.description1}
						serializerOptions={{
							enableFileIconAnchors: false,
							enableMediaLinks: false,
						}}
					/>
				</StyledTextContainer>
			</ContentContainer>
		</FullBleedGrid>
	)
}

const ContentContainer = styled.div`
	padding: var(--spacer-xl) 0 var(--spacer-base);
	grid-column: 2 / span 6;

	${media.tablet`
		grid-column: 2 / -2;
	`}

	${media.phone`
		padding: var(--spacer-md) 0;
	`}
`

const SectionTitle = styled.h3`
	margin: 0;
	padding-bottom: var(--spacer-sm);
	font-family: AdobeCaslonPro, serif;
	font-size: 2.0625rem;
	font-weight: 600;
	line-height: 2.5rem;
	color: ${(props) => props.theme.colors.grey600};

	${media.phone`
		font-size: 1.4375rem;
	`}
`

const StyledTextContainer = styled.div`
	p {
		font-family: AdobeCaslonPro;
		font-size: 1.375rem;
		line-height: 2rem;
		color: ${(props) => props.theme.colors.grey600};

		${media.phone`
			font-size: 1.125rem;
		`}
	}
`

export default SingleRowTitleDescription
