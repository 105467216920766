import React from 'react'
import styled, { css } from 'styled-components'
import { map } from 'lodash'
import { RichTextBlock } from 'prismic-reactjs'
import Button, { ButtonBackgroundType, IconPlacement } from '@kvika/button'
import { ChevronRight } from '@kvika/icons'

import { IPage } from '../../../types/prismic/pages/generalPage'
import { FullBleedGrid, Grid } from '../../Layout/Grid'
import RichTextCard from '../../Cards/RichTextCard'
import { media } from '../../../utils/style-utils'
import {
	IBaseSlice,
	IPrismicDocumentLink,
} from '../../../types/prismic/baseTypes'
import { FullBleedSectionTitle } from '../../Section/SectionTitle'
import { usePrismicRoutingContext } from '../../../utils/prismic/Context'
import { resolvePrismicLink } from '../../../utils/prismic/RouteHelpers'
export interface IRichTextCardsSlice extends IBaseSlice {
	items: {
		content: RichTextBlock[]
		is_card?: boolean
	}[]
	slice_type: 'richtext_cards'
	primary: {
		section_color?: IPage.Section['section_color']
		background_color?:
			| 'transparent'
			| 'Gold50'
			| 'Gold100'
			| 'Grey50'
			| 'Grey800'
		section_title?: string
		content_heading?: string
		link?: IPrismicDocumentLink
		link_text?: string
	}
}

interface IProps {
	sliceData: IRichTextCardsSlice
}

const RichTextCards: React.FC<IProps> = ({ sliceData }) => {
	const isSection: boolean = !!sliceData.primary.section_color
	const hasSectionTitle = !!sliceData.primary.section_title
	const hasContentHeading = !!sliceData.primary.content_heading

	const pages = usePrismicRoutingContext().pages
	const link = sliceData.primary.link
		? resolvePrismicLink(sliceData.primary.link, pages)
		: undefined

	return (
		<StyledFullBleedGrid
			hasSectionTitle={hasSectionTitle}
			isSection={isSection}
			backgroundColor={sliceData.primary.section_color}
		>
			{hasSectionTitle && (
				<FullBleedSectionTitle title={sliceData.primary.section_title} />
			)}
			{hasContentHeading && (
				<Heading hasSectionTitle={hasSectionTitle}>
					{sliceData.primary.content_heading}
				</Heading>
			)}
			<CardsContainer>
				{map(sliceData.items, (card, index) => (
					<StyledRichTextCard
						card={card}
						isSection={isSection}
						hasSectionTitle={hasSectionTitle}
						hasContentHeading={hasContentHeading}
						key={`card-${index}`}
						background_color={sliceData.primary.background_color}
						isCard={card.is_card}
					/>
				))}
			</CardsContainer>
			{link && sliceData.primary.link_text && (
				<ButtonWrapper>
					<Button
						backgroundType={ButtonBackgroundType.Text}
						icon={<ChevronRight />}
						iconPlacement={IconPlacement.RIGHT}
					>
						{sliceData.primary.link_text}
					</Button>
				</ButtonWrapper>
			)}
		</StyledFullBleedGrid>
	)
}

export default RichTextCards

const CardsContainer = styled.div`
	grid-column: 2 / -2;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 16px;
`

const Heading = styled.h1<{ hasSectionTitle: boolean }>`
	grid-column: 2 / -2;
	padding-top: var(--spacer-lg);

	${(p) => p.hasSectionTitle && 'padding-top: 0'};
`

const ButtonWrapper = styled.div`
	grid-column: 2 / -2;
	margin-top: var(--spacer-md);
`

const StyledRichTextCard = styled(RichTextCard)<{
	background_color?: string
	isSection?: boolean
	hasSectionTitle?: boolean
	hasContentHeading?: boolean
	isCard?: boolean
}>`
	${({ theme: { colors }, background_color }) => css`
		background-color: ${background_color
			? colors[background_color.toLowerCase()]
			: 'inherit'};
	`}

	flex-basis: 32%;
	flex-grow: 3;

	${(p) =>
		p.isSection && (p.hasSectionTitle || p.hasContentHeading)
			? css`
					padding: 0;
					padding-right: 32px;
			  `
			: css`
					padding: 40px 32px;
			  `}

	${(p) =>
		p.isSection &&
		!p.hasSectionTitle &&
		!p.hasContentHeading &&
		css`
			margin-top: var(--spacer-base);
		`};

	${(p) =>
		!p.isCard &&
		css`
			flex-basis: 100%;
			max-width: 76ch;
			margin-top: var(--spacer-xs);
		`}

	${media.phone`
		flex-basis: auto;
		${(p) =>
			!p.isCard &&
			css`
				margin-top: unset;
			`}
	`}
`

const StyledFullBleedGrid = styled(FullBleedGrid)<{
	backgroundColor?: string
	hasSectionTitle: boolean
	isSection: boolean
}>`
	${({ theme: { colors }, backgroundColor }) => css`
		background-color: ${backgroundColor
			? colors[backgroundColor.toLowerCase()]
			: 'inherit'};
	`}

	padding-bottom: var(--spacer-base);
	${(p) =>
		p.hasSectionTitle &&
		css`
			margin-bottom: var(--spacer-base);
		`};

	${(p) =>
		p.hasSectionTitle &&
		css`
			padding-top: var(--spacer-base);
		`}
`
