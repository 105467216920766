import Select, { SelectWidth } from '@kvika/select'
import { ErrorMessage, Field } from 'formik'

import {
	InputWrapper,
	StyledValidationError,
} from '../../FormikForm/FormikInputText/FormikInputText'

interface IFormikInputSelectProps {
	label: string
	value: {
		value: string | number
		label: string
	}
	options: {
		value: string | number
		label: string
	}[]
	onChange: (value) => void
	onBlur?: (value) => void
	width?: SelectWidth | string
	id: string
	className?: string
	placeholder?: string
	formikName?: string
}

interface IFormikInputSelectComponentProps {
	label: string
	value: {
		value: string | number
		label: string
	}
	options: {
		value: string | number
		label: string
	}[]
	onChange: (value) => void
	onBlur?: (value) => void
	width?: SelectWidth | string
	id: string
	className?: string
	placeholder?: string
	name?: string
	meta?: any
}

const InputComponent = ({
	label,
	value,
	onChange,
	options,
	width,
	id,
	className,
	placeholder,
	name,
	onBlur,
	meta,
}: IFormikInputSelectComponentProps) => {
	return (
		<InputWrapper className={className} fullWidth={false}>
			<label htmlFor={id}>{label}</label>
			<Select
				classNamePrefix={'selected-option'}
				id={id}
				instanceId={id}
				value={value}
				onChange={onChange}
				options={options}
				width={width}
				placeholder={placeholder}
				onBlur={onBlur}
			/>
			{name && meta && meta.error && meta.touched && (
				<StyledValidationError>
					<ErrorMessage name={name} />
				</StyledValidationError>
			)}
		</InputWrapper>
	)
}

const InputSelect = ({
	label,
	value,
	onChange,
	options,
	width = 'unset',
	id,
	className,
	placeholder,
	formikName: name,
	onBlur,
}: IFormikInputSelectProps) => {
	return (
		<>
			{name ? (
				<Field name={name}>
					{({ meta }) => (
						<InputComponent
							label={label}
							value={value}
							onChange={onChange}
							options={options}
							width={width}
							id={id}
							className={className}
							placeholder={placeholder}
							name={name}
							onBlur={onBlur}
							meta={meta}
						/>
					)}
				</Field>
			) : (
				<InputComponent
					label={label}
					value={value}
					onChange={onChange}
					options={options}
					width={width}
					id={id}
					className={className}
					placeholder={placeholder}
					name={name}
					onBlur={onBlur}
				/>
			)}
		</>
	)
}

export default InputSelect
