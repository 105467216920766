import React from 'react'
import styled, { css } from 'styled-components'
import { RichTextBlock } from 'prismic-reactjs'

import { FullBleedGrid } from '../../Layout/Grid'
import RichText from '../../RichText/RichText'
import { LineBorder } from '../../icons'
import { media } from '../../../utils/style-utils'
import {
	IBaseSlice,
	IPrismicImage,
	IPrismicLink,
} from '../../../types/prismic/baseTypes'

import HighlightImages from './HighlightImages'
import HighlightButton from './HighlightButton'

export interface IHighlightSlice extends IBaseSlice {
	primary: {
		description1: RichTextBlock[]
		button_text: string
		button_link: IPrismicLink
		color_variation: 'Default' | 'Gold50' | 'Gold100' | 'Grey50' | 'Grey800'
		size_variation: 'small' | 'full'
		line_break_variation: 'none' | 'top' | 'bottom' | 'both'
		bottom_margin?: boolean
	}
	items: {
		image: IPrismicImage
		max_width?: string
		image_link?: {
			url?: string
		}
	}[]
	slice_type: 'highlight'
}

export interface IProps {
	sliceData: IHighlightSlice
}

const Highlight = ({ sliceData }: IProps) => {
	const size = sliceData.primary.size_variation
	const lineBreakVariation = sliceData.primary.line_break_variation
	const showTopLines =
		lineBreakVariation === 'both' || lineBreakVariation === 'top'
	const showBottomLines =
		lineBreakVariation === 'both' || lineBreakVariation === 'bottom'

	return (
		<GridContainerFull size={size}>
			<ContentContainer
				size={size}
				backgroundColor={sliceData.primary.color_variation}
				hasBottomMargin={sliceData.primary.bottom_margin}
			>
				{showTopLines && (
					<LineBorderContainer size={size} position='top'>
						<LineBorder />
					</LineBorderContainer>
				)}
				<SpacingContainer
					size={size}
					showTopLines={showTopLines}
					showBottomLines={showBottomLines}
				>
					<RichText content={sliceData.primary.description1} />
					<HighlightImages items={sliceData.items} />
					{sliceData.primary.button_text && sliceData.primary.button_link && (
						<HighlightButton primary={sliceData.primary} />
					)}
				</SpacingContainer>

				{showBottomLines && (
					<LineBorderContainer size={size} position='bottom'>
						<LineBorder />
					</LineBorderContainer>
				)}
			</ContentContainer>
		</GridContainerFull>
	)
}

const SpacingContainer = styled.div<{
	size: 'small' | 'full'
	showTopLines?: boolean
	showBottomLines?: boolean
}>`
	${({ size }) =>
		size === 'small'
			? css`
					padding: var(--spacer-lg) var(--spacer-base);

					${media.phone`
						padding: var(--spacer-base) var(--spacer-md);
					`}
			  `
			: css`
					padding: var(--spacer-base) var(--spacer-md);
			  `};

	${({ showTopLines, size }) =>
		showTopLines && size !== 'small'
			? css`
					padding-top: var(--spacer-md);
			  `
			: css`
					padding-top: var(
						${size === 'small' ? '--spacer-sm' : '--spacer-base'}
					);

					${media.phone`
						padding-top: var(--spacer-md);
					`}
			  `}

	${({ showBottomLines, size }) =>
		showBottomLines && size !== 'small'
			? css`
					padding-bottom: var(--spacer-md);
			  `
			: css`
					padding-bottom: var(
						${size === 'small' ? '--spacer-sm' : '--spacer-base'}
					);

					${media.phone`
						padding-bottom: var(--spacer-md);
					`}
			  `}
`

const GridContainerFull = styled(FullBleedGrid)<{ size: 'small' | 'full' }>`
	margin-bottom: ${({ size }) => (size === 'small' ? 'var(--spacer-xs)' : '0')};

	${media.phone`
		margin-bottom: 0;
	`}
`

const ContentContainer = styled.div<{
	size: 'small' | 'full'
	backgroundColor?: string
	hasBottomMargin?: boolean
}>`
	grid-column: ${(props) => (props.size === 'full' ? '1 / -1' : '2 / -2')};
	text-align: center;

	${({ size, hasBottomMargin }) =>
		size === 'full' &&
		hasBottomMargin &&
		css`
			margin-bottom: var(--spacer-base);
		`}

	${({ theme: { colors }, backgroundColor }) => css`
		background-color: ${backgroundColor
			? colors[backgroundColor.toLowerCase()]
			: 'inherit'};

		color: ${backgroundColor === 'Grey800' ? colors.gold400 : colors.grey600};
	`};

	${media.phone`
		grid-column: 1 / -1;
	`}
`

const LineBorderContainer = styled.div<{
	position: 'top' | 'bottom'
	size: 'small' | 'full'
}>`
	display: flex;
	justify-content: center;
	align-items: center;

	${({ position, size }) =>
		position === 'top'
			? `padding-top: var(${
					size === 'small' ? '--spacer-md' : '--spacer-base'
			  })`
			: `padding-bottom: var(${
					size === 'small' ? '--spacer-md' : '--spacer-base'
			  })`};
`

export default Highlight
