import React, { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'

import { IBaseSlice } from '../../types/prismic/baseTypes'
import { IPage } from '../../types/prismic/pages/generalPage'

export interface ISpacerSlice extends IBaseSlice {
	primary: {
		space: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'base' | '2xl'
		section_color?: IPage.Section['section_color']
	}
	slice_type: 'spacer'
}

type Props = ISpacerSlice['primary']

const Spacer: React.FC<Props> = ({ space, section_color }) => {
	const theme = useContext(ThemeContext)

	if (!space) {
		return null
	}

	const backgroundColor =
		section_color && theme.colors[section_color.toLocaleLowerCase()]

	return <SpacerWrapper space={space} backgroundColor={backgroundColor} />
}

export default Spacer

const SpacerWrapper = styled.div<{
	space: Props['space']
	backgroundColor: string
}>`
	height: ${(p) => `var(--spacer-${p.space})`};
	${(p) =>
		p.backgroundColor &&
		css`
			background-color: ${p.backgroundColor};
		`}
`
