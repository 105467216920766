import { RichTextBlock } from 'prismic-reactjs'
import React from 'react'
import styled from 'styled-components'

import RichText from '../RichText/RichText'

interface Props {
	card: {
		content: RichTextBlock[]
	}
	className?: string
}

const RichTextCard: React.FC<Props> = ({ card, className }) => {
	return (
		<Card className={className}>
			<StyledRichText content={card.content} />
		</Card>
	)
}

export default RichTextCard

const Card = styled.div`
	width: auto;
	padding: 40px 32px;
	background: ${({ theme }) => theme.colors.grey50};
`

const StyledRichText = styled(RichText)`
	p {
		margin: 0;
		padding: 8px 0 var(--spacer-xs);

		&:last-child {
			padding-bottom: 0;
		}
	}
`
